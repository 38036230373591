.demoBlock {
  &:last-of-type {
    padding-bottom: 32px;
  }
}

.title {
  padding: 12px 8px 6px;
  color: #969696;
  font-size: 14px;
}

.main {
  border: solid 1px var(--adm-border-color);
  border-right: none;
  border-left: none;
}

.grid-demo-item-block {
  //border: solid 1px #999999;
  //background: #f5f5f5;
  //text-align: center;
  //color: #999999;
  //height: 100%;
}

.right {
  text-align: right;
}


.visual-canvas {
  width: 100%;
  min-width: unset !important;
}