.demoBlock:last-of-type {
  padding-bottom: 32px;
}
.title {
  padding: 12px 8px 6px;
  color: #969696;
  font-size: 14px;
}
.main {
  border: solid 1px var(--adm-border-color);
  border-right: none;
  border-left: none;
}
.right {
  text-align: right;
}
.visual-canvas {
  width: 100%;
  min-width: unset !important;
}
